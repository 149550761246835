// sticky nav
document.addEventListener("DOMContentLoaded", () => {

// check if the class exists before applying gsap
let procMenuExist = document.getElementsByClassName("section__proc--menu");

if (procMenuExist.length > 0) {
    gsap.registerPlugin(ScrollTrigger);
    const mm = gsap.matchMedia();
    gsap.to(".section__proc--menu", {
    scrollTrigger: {
        trigger: ".section__proc--menu",
        pin: true,
        start: "top top+=72",
        endTrigger: ".section__schedule",
        end: "bottom bottom+=20",
    },
    });
}
});

// url fragment
window.onload = function () {
    if (location.hash) {
        var targetId = location.hash.substring(1);
        var targetElement = document.getElementById(targetId);
        var stickyNavHeight = document.querySelector('.header').offsetHeight;
        var procMenuHeight = document.querySelector('.section__proc--menu').offsetHeight;
        var padding = 50;

        if (targetElement) {
            var offsetTop = targetElement.getBoundingClientRect().top + window.scrollY - (stickyNavHeight + procMenuHeight + padding);
            window.scroll({
                top: offsetTop,
                left: 0,
                behavior: 'smooth'
            });
        }
    }
};

// procedure dropdown nav
const dropdowns = document.querySelectorAll('.dropdown');

dropdowns.forEach(dropdown => {
    const select = dropdown.querySelector('.select');
    // const caret = dropdown.querySelector('.caret');
    const menu = dropdown.querySelector('.menu');
    const options = dropdown.querySelectorAll('.menu li');
    const selected = dropdown.querySelector('.selected');


    select.addEventListener('click', () => {
        select.classList.toggle('select-clicked');
        // caret.classList.toggle('caret-rotate');
        menu.classList.toggle('menu-open');
    });

    options.forEach(option => {
        option.addEventListener('click', () => {
            selected.innerText = option.innerText;
            select.classList.remove('select-clicked');
            // caret.classList.remove('caret-rotate');
            menu.classList.remove('menu-open');

            options.forEach(option => {
                option.classList.remove('active');
            });
            option.classList.add('active');
        });
    });
});
