const body = document.querySelector('body');
const banner = document.querySelector('body > main > .hero');
const menuTrigger = document.querySelector('button.menu-toggle');

function updateMenuTrigger() {
    if (banner && menuTrigger) {
        const bannerBottomPosition = banner.offsetHeight;
        scrollPosition = window.pageYOffset;

        if (scrollPosition > (bannerBottomPosition - menuTrigger.offsetTop - menuTrigger.offsetHeight / 2)) {
            body.classList.add('js-menu-trigger-is-inverted');
        } else {
            body.classList.remove('js-menu-trigger-is-inverted');
        }
    }
}

window.addEventListener('resize', () => {
    updateMenuTrigger();
});

window.addEventListener('scroll', () => {
    updateMenuTrigger();
});