document.addEventListener("DOMContentLoaded", function(){

	function handleIntersection(entries){
		entries.map((entry) => {
				if(entry.isIntersecting){
					entry.target.style.backgroundImage = "url('" + entry.target.dataset.bgimg + "')"
					observer.unobserve(entry.target);
				}
			});
	}

	const backgrounds = document.querySelectorAll('.lazyload-bg');
	const observer = new IntersectionObserver(
  			handleIntersection,
  			{ 
  				rootMargin: "100px" 
  			}
		);
	backgrounds.forEach(
			background => observer.observe(background)
		);
});