// on scroll

let scrollpos = window.scrollY;
const header = document.querySelector("header");
const header_height = header.offsetHeight;


const add_class_on_scroll = () => header.classList.add("bg");
const remove_class_on_scroll = () => header.classList.remove("bg");


window.addEventListener('scroll', function () {
    scrollpos = window.scrollY;

    if (scrollpos >= header_height) {add_class_on_scroll();} else
    {remove_class_on_scroll();}
}, 100);


const menu = document.querySelector(".menu-toggle");
const menu_height = menu.offsetHeight;


const add_menu_on_scroll = () => menu.classList.add("bg");
const remove_menu_on_scroll = () => menu.classList.remove("bg");


window.addEventListener('scroll', function () {
    scrollpos = window.scrollY;

    if (scrollpos >= menu_height) {add_menu_on_scroll();} else
    {remove_menu_on_scroll();}
}, 100);