// swiperjs

// Initializing Swiper
var swiper = new Swiper('.section__banner--slide', {
    effect: "fade",
    spaceBetween: 30,
    centeredSlides: true,
    speed: 4000,
    autoplay: {
        delay: 3500,
        disableOnInteraction: false,
    },
});

var swiper2 = new Swiper('.section__doctor--slide', {
    effect: "fade",
    spaceBetween: 30,
    centeredSlides: true,
    autoplay: {
        delay: 2500,
        disableOnInteraction: false,
    },
});

var swiper3 = new Swiper('.section__gallery--slide', {
    slidesPerView: 2,
    spaceBetween: 0,
    rewind: true,
    navigation: {
        nextEl: ".inline-gallery-next",
        prevEl: ".inline-gallery-prev",
    },
    pagination: {
        el: ".inline-gallery-pagination",
        type: "fraction",
    },
    breakpoints: {
        100: {
            slidesPerView: 1,
        },
        1024: {
            slidesPerView: 1,
        },
        1200: {
            slidesPerView: 2,
        },
    },
});

var swiper4 = new Swiper('.section__reviews--slide', {
    slidesPerView: 1,
    spaceBetween: 0,
    rewind: true,
    navigation: {
        nextEl: ".inline-reviews-next",
        prevEl: ".inline-reviews-prev",
    },
    // pagination: {
    //     el: ".inline-reviews-pagination",
    //     type: "fraction",
    // },
});